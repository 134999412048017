import './header.styles.scss'
import Nav from '../Nav/Nav'
const Header = () => {
    return (
        <div className='header-container'>
            <Nav/>
            <h2 className='KronaOne text-lg text-dark'>Meet the</h2>
            <h1 className='PermanetMarker gradient-text text-xl'>Icons</h1>
            <div className='d-flex flex-row align-items-center'>
                <a target="_blank" href="https://discord.gg/thedopestudio" className='btn btn-outline-dark text-md DMSans h-pink'>Discord</a>
                <a target="_blank" href="https://the-dope-studio-1.gitbook.io/the-dope-studio/" className='btn btn-outline-dark text-md DMSans mx-4 h-blue'>WhitePaper</a>
                <a target="_blank" href="https://twitter.com/thedopestudio" className='btn btn-outline-dark text-md DMSans'>Twitter</a>
            </div>
        </div>
    )
}

export default Header