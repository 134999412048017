import "./Nav.styles.scss";
import Logo from "../../imgs/logo.png";
const Nav = () => {
  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark  px-5 w-100 mt-5">
        <a href="/" className="monaco text-md mars  no-display">
          <img className="nav-logo img-fluid" src={Logo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar7"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="navbar-collapse collapse justify-content-end"
          id="navbar7"
        >
          <ul className="navbar-nav ml-auto">

            <li className="nav-item">
              <a
                href="#showcase"
                className="nav-link text-dark DMSans text-sm"
              >
                The Art
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#showcase"
                className="nav-link text-dark DMSans text-sm"
              >
                Roadmap
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#faq"
                className="nav-link text-dark DMSans text-sm"
              >
                F.A.Q
              </a>
            </li>

          </ul>
        </div>
      </nav>
    </>
  );
};

export default Nav;
