import "./Faq.styles.scss";

const Faq = () => {
  return (
    <div id="faq" className="faq-container">
      <div className="faq-content-container" id="faq">
        <div className="line-container">
          <h2 className="shadow-text text-xl ">FAQs</h2>
          <h3 className="text-md text-dark DMSans text-center">Have a question? We might have the answer.</h3>
          <div className="green-line"></div>
        </div>
        <h3 className="text-lg text-dark PermanetMarker text-center mb-5">Frequently Asked Questions</h3>
        <div className="accordion" id="accordionExample">

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button text-md title-font rounded text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                When is the release date?

              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              TBD.

              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What's the cost per NFT
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
                TBD.
              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                What is the quantity?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
                This is TBD.
              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Do you have a token?

              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              We're currently exploring the right utility token system.  We will have more details to share on this at a later date. This is still a maybe, but something we are currently exploring.  This token would be usable inside of Bl3nd and our ecosystem. 
              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="true"
                aria-controls="collapseFive"
              >
                Where does art get shipped?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              Our physical artwork can be shipped globally, so no matter where you live, you can show off your favorite artists work or your forever NFT on your wall. 

              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                What is Bl3nd?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              Bl3nd is our web2 & web3 tool that will bridge the gap between the two in a harmonious way.  We are very excited to share this with you all in more details.  This will initially be a TDS exclusive product, but will soon be branching out to other NFT communities as it grows in scale. 

              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="true"
                aria-controls="collapseSeven"
              >
                Will influencers dump on my head?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              Nope, no free Icons being given out to your favorite neighborhood influencers.

              </div>
            </div>
          </div>

          <div className="accordion-item rounded mb-5">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button text-md title-font rounded text-dark"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                How many NFTs will the team get?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body text-dark">
              This is still TBD, but it will be a small number (less than 50).
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Faq;
