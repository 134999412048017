import Header from "./components/header/Header";
import About from "./components/About/About";
import Carousel from "./components/Carousel/Carousel";
import Burn from "./components/BurnSystem/Burn";
import Roadmap from "./components/Roadmap/Roadmap";
import Royalties from "./components/Royalties/Royalties";
import Faq from "./components/FAQ/Faq";
import paper from "./imgs/paper-bg.png";
function App() {
  return (
    <div style={{ backgroundImage: `url(${paper})` }}>
      <Header />
      <About />
      <Carousel />
      {/* <Burn /> */}
      <Roadmap />
      {/* <Royalties /> */}
      <Faq />
    </div>
  );
}

export default App;
