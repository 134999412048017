import "./About.styles.scss";

const About = () => {
  return (
    <div id="about" className="about-container">
      <div className="about-container-content">
        <div className="line-container">
            <h2 className="shadow-text text-xl">Learn More</h2>
          <h3 className="text-md text-dark PermanetMarker">About The Icons</h3>
          <div className="green-line"></div>
          </div>
          <p className="text-md text-dark my-4">
          The Dope Studio (TDS) is a NFT brand looking to blend the line between the physical and digital. The goal is to create several tools that <span className="text-blue">benefit both web2 and web3 harmoniously. </span> 

The first tool we're creating is a platform that curates quality art from hand-picked artists, whether digitally via NFT’s and 1 of 1’s, or physically via paintings, sculptures and other forms of medium. You'll also have the ability to print any image that you want, as long as it is a hi-res image.

The second tool we're creating, <span className="text-blue">Bl3nd</span>, is a marketplace that will bring web2 brands and web3 communities together.
           </p><a target="_blank" href="https://discord.gg/thedopestudio" className='btn btn-outline-dark text-md DMSans mt-3'>learn more</a>

      </div>
    </div>
  );
};

export default About;
