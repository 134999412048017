import './Carousel.styles.scss'
import Slide1 from '../../imgs/slide-1.png'
import Slide2 from '../../imgs/slide-2.png'
import Slide3 from '../../imgs/slide-3.png'
import Slide4 from '../../imgs/slide-4.png'
import Slide5 from '../../imgs/slide-5.png'
import Slide6 from '../../imgs/slide6.png'
// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Carousel = () => {
    return (
        <div id="showcase" className='carousel-container'>
            <div className='carousel-content-container'>
            <div className="line-container">
            <h2 className="shadow-text text-xl">Meet The Icon</h2>
          <h3 className="text-md text-dark DMSans">Meet The Icons</h3>
          <div className="green-line"></div>
          </div>
                <h2 className='text-lg text-dark PermanetMarker'>The Most Iconic Pandas On SUI</h2>
                <Splide
      options={ {
        rewind: true,
        gap   : '3rem',
        perPage: 3,
        breakpoints: {
          640: {
            perPage: 1,
          },
        }
      } }
      aria-label="My Favorite Images"
    >
      <SplideSlide>
        <img src={Slide1} alt="Image 1"/>
      </SplideSlide>
      <SplideSlide>
        <img src={Slide2} alt="Image 2"/>
      </SplideSlide>
      <SplideSlide>
        <img src={Slide3} alt="Image 3"/>
      </SplideSlide>
      <SplideSlide>
        <img src={Slide4} alt="Image 3"/>
      </SplideSlide>
      <SplideSlide>
        <img src={Slide5} alt="Image 3"/>
      </SplideSlide>
      <SplideSlide>
        <img src={Slide6} alt="Image 3"/>
      </SplideSlide>
    </Splide>
            </div>


        </div>
    )
}

export default Carousel