import "./Roadmap.styles.scss";
import RoadmapImg from '../../imgs/roadmap.png'
import MobileRoadmap from '../../imgs/mobile-roadmap.png'

const Roadmap = () => {
    return (
        <div className='roadmap-container'>
            <div className='roadmap-content-container'>
            <div className="line-container">
            <h2 className="shadow-text text-xl">Building</h2>
          <h3 className="text-md text-dark DMSans">A peek into what’s coming.</h3>
          <div className="green-line"></div>
          </div>
          <h3 className="text-md text-dark PermanetMarker">What we're building</h3>
          <p className="text-md text-dark my-4">
            It’s easy to blend in. These pandas are standing out and being
            <span className="text-blue"> Iconic.</span> Creating a platform so art lovers can <span className="text-blue">get physical pieces
            from their favorite 1/1 artists.</span> Blending Web2 and Web3 via a
            raffle/auction system for holders of select NFT projects inside of a
            larger ecosystem.</p>
            <img className="my-5 img-fluid desktop-roadmap" src={RoadmapImg}/>
            <img className="my-5 img-fluid mobile-roadmap" src={MobileRoadmap}/>
            <h2 className="shadow-text text-xl">Get whitelisted</h2>
            <h3 className="text-md text-dark DMSans">Want to get Whitelisted? Come join the discord and <span className="text-blue">vibe</span> with us.</h3>
            <a target="_blank" href="https://discord.gg/thedopestudio" className='btn btn-outline-dark text-md DMSans mt-4 mx-auto'>Whitelist</a>
            </div>
        </div>
    )
}

export default Roadmap